import * as React from 'react';
import LayoutContainer from '../containers/layout';
import BlackHomepage from '../images/black-homepage.svg';
import BlackTelephone from '../images/black-telephone.svg';
import BlackEnvelope from '../images/black-envelope.svg';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

const ContactPage = () => {
  return (
    <LayoutContainer title="Kontakt" description="Skontaktuj się z nami">
      <div className="container mx-auto px-6 pt-16 mb-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="text-4xl lg:text-5xl text-black">Kontakt</div>
          <div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div className="flex">
                <BlackHomepage className="mr-3 mt-1" />
                <div className="text-black">
                  ul. Emaus 55Y/103 <br />
                  30-213 Kraków
                </div>
              </div>
              <div>
                <div className="flex">
                  <BlackTelephone className="mr-3 mt-1" />
                  <a href="tel:+48 737 339 958">
                    <div className="text-black">tel. +48 737 339 958</div>
                  </a>
                </div>
                <div className="flex">
                  <BlackEnvelope className="mr-3 mt-1" />
                  <a href="mailto:admbiuroin@belgravia.edu.pl">
                    <div className="text-black">biuro@belgravia.edu.pl</div>
                  </a>
                </div>
              </div>
              <div className="lg:col-span-2">
                <div className="mb-2">Godziny otwarcia:</div>
                <div>poniedziałek - piątek: godz. 10:00 - 19:00</div>
                <div>sobota: godz. 10:00-15:00</div>
                <div>niedziela: zamknięte</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {typeof window !== 'undefined' && (
        <div className="h-[500px]">
          <MapContainer center={[50.06655, 19.88838]} zoom={16} scrollWheelZoom={true} style={{ height: '100%', zIndex: 0 }}>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker position={[50.06655, 19.88838]} />
          </MapContainer>
        </div>
      )}
    </LayoutContainer>
  );
};

export default ContactPage;
